<template>
  <div class="wrapper">
    <v-autocomplete
      v-model="model"
      flat
      no-filter
      solo
      background-color="#F3F3F3"
      prepend-inner-icon="mdi-magnify"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      hide-no-data
      hide-details
      :item-text="getName"
      item-value="urlcomponent"
      :label="searchText"
      @keyup.enter="goToSearch"
      @click:prepend-inner="goToSearch"
    >
      <template #item="data">
        <v-list-item-avatar width="50" height="50" tile>
          <img
            :src="
              (data.item.photos &&
                data.item.photos.length &&
                data.item.photos[0].url) ||
              'https://ozparts.eu/img/photo-holder.f4760517.png'
            "
          />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.name"></v-list-item-title>
          <v-list-item-subtitle
            v-html="data.item.oemmanufacturer"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-icon class="cog" v-bind="attrs" v-on="on" @click="changeMode">
          mdi-cog
        </v-icon>
      </template>
      <span>Change search mode</span>
    </v-tooltip>
    <universal-modal :modal="modal" @closeModal="closeModal" />
  </div>
</template>

<script>
import { debounce } from "lodash";
import { hasLoginError } from "../../helpers";
import { mapGetters, mapState } from "vuex";
import UniversalModal from "@/components/common/UniversalModal.vue";

export default {
  name: "SearchBar",
  components: { UniversalModal },
  data() {
    return {
      modeIndex: 0,
      modes: ["normal", "deep", "oem"],
      isLoading: false,
      items: [],
      model: "",
      search: "",
      searchMinimalLength: 1,
      modal: {
        header: "Info",
        text: "",
        show: false,
      },
    };
  },
  computed: {
    ...mapGetters(["getToken"]),
    ...mapState(["lang"]),
    searchText() {
      switch (this.modes[this.modeIndex]) {
        case "normal":
          return this.lang === "pl" ? "Według numeru..." : "By part number...";
        case "deep":
          return this.lang === "pl" ? "Według modelu..." : "By make & model...";
        case "oem":
          return this.lang === "pl" ? "Według numeru OEM" : "By OEM...";
        default:
          return this.lang === "pl" ? "Według numeru..." : "By part number...";
      }
    },
  },
  watch: {
    model(selected) {
      if (selected) {
        this.model = "";
        this.search = "";
        this.items = [];
        this.$router.push("/" + selected);
      }
    },
    search: debounce(async function (keyword) {
      if (keyword) {
        this.isLoading = true;
        try {
          let payload = {
            action: "search",
            keyword,
          };
          if (this.modes[this.modeIndex] === "deep") {
            payload = { ...payload, deep: true };
          }
          if (this.modes[this.modeIndex] === "oem") {
            payload = { ...payload, byOEM: true };
          }
          const res = await this.$http.post("/search", payload, {
            headers: {
              Authorization: `Bearer ${this.getToken}`,
            },
          });
          this.items = res.data;
          if (res.data.length && res.data.some((item) => !item.price)) {
            this.$store.commit("logOut");
          }
        } catch (err) {
          this.error(err);
        } finally {
          this.isLoading = false;
        }
      }
    }, 500),
  },
  methods: {
    closeModal() {
      this.modal.show = false;
    },
    error(err) {
      const error = (err.response && err.response.data) || err;
      if (hasLoginError(error.code)) {
        this.$store.commit("logOut");
        this.$router.push("/");
      } else {
        this.modal.header = "Info";
        this.modal.text = error.message || error.errmsg || error;
        this.modal.code = error.code;
        this.modal.show = true;
      }
    },
    goToSearch() {
      let url = `/search/${this.search}`;
      if (
        this.search.length > this.searchMinimalLength &&
        this.search !== this.$route.params.phrase
      ) {
        this.model = "";
        this.search = "";
        this.items = [];
        this.$router.push(url).catch((err) => {
          this.error(err);
        });
      }
    },
    getName(item) {
      if (this.modes[this.modeIndex] === "oem") {
        return item.oem;
      } else {
        return item.name;
      }
    },
    changeMode() {
      this.items = [];
      this.model = "";
      this.search = "";
      this.modeIndex =
        this.modeIndex + 1 >= this.modes.length ? 0 : this.modeIndex + 1;
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;

  @media screen and (min-width: 600px) {
    max-width: 480px;
  }
}

.cog {
  transform: translateX(-65px);
}
</style>
