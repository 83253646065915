<template>
  <div class="lower-part">
    <!-- <hr class="line" /> -->
    <div class="nav-buttons">
      <div class="nav-button" @click="setMobileMenu(!showMobileMenu)">Menu</div>
      <transition name="fade">
        <div v-if="showMobileMenu">
          <div v-if="isUserLogged" class="nav-button" @click="logOut">
            Logout
          </div>
          <div
            v-for="item in itemsFiltered"
            :key="item.title"
            :to="item.to"
            active-class="link-active"
            class="nav-button"
            @click="goto(item.to)"
          >
            {{ item.title }}
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "NavLowerPart",
  computed: {
    ...mapState(["showMobileMenu"]),
    ...mapGetters(["isUserLogged"]),
    itemsFiltered() {
      if (this.$source === "ozpartsuk") {
        return [
          ...this.items,
          { title: "FAQ", icon: "question_answer", to: "/faq" },
        ];
      } else {
        return this.items;
      }
    },
  },
  methods: {
    ...mapMutations(["setMobileMenu", "logOut"]),
    goto(where) {
      this.setMobileMenu(false);
      this.$router.push(where);
    },
  },
  data: () => ({
    showFullMenu: false,
    items: [
      { title: "About us", icon: "star", to: "/about" },
      { title: "Catalogue", icon: "build", to: "/catalogue" },
      { title: "Brands", icon: "build", to: "/brands" },
      { title: "News", icon: "explore", to: "/news" },
      { title: "Contact us", icon: "question_answer", to: "/contact" },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
  max-height: 250px;
}
.fade-enter,
.fade-leave-to {
  max-height: 0;
  opacity: 0;
}

.link-active {
  background-color: var(--red);
  color: white;
}

.nav-buttons {
  margin-top: 20px;
  max-height: 350px;
}

.nav-button {
  display: block;
  text-align: center;
  background-color: var(--red);
  color: white;
  margin: 4px 0;
  padding: 10px 0;
}

.line {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0.3em 0;
  padding: 0;
}
</style>
