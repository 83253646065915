<template>
  <div class="lower-part">
    <hr class="line" />
    <div class="flex">
      <div class="nav-buttons">
        <router-link
          v-for="item in itemsFiltered"
          :key="item.title"
          :to="item.to"
          tag="div"
          active-class="link-active"
          class="nav-button"
          >{{ item.title }}</router-link
        >
      </div>
      <v-spacer />
      <router-link
        :to="{ name: 'stock-clearance' }"
        tag="div"
        :class="{ active: $route.name === 'stock-clearance' }"
        class="nav-button-special nav-button"
        >{{ $t("deadstock") }}</router-link
      >
      <div class="icon">
        <a href="https://www.facebook.com/Ozparts.eu/" target="_blank">
          <img
            class="icon-item"
            src="./../../../assets/facebook.png"
            alt="Facebook"
            width="30px"
            height="30px"
          />
        </a>
        <a href="https://www.instagram.com/ozparts/" target="_blank">
          <img
            class="icon-item instagram"
            src="./../../../assets/instagram.png"
            alt="Instagram"
            width="40px"
            height="37px"
          />
        </a>
      </div>
      <language-icon />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import LanguageIcon from "./LanguageIcon.vue";

export default {
  name: "NavLowerPart",
  components: { LanguageIcon },
  computed: {
    ...mapState(["lang"]),
    itemsFiltered() {
      if (this.$source === "ozpartsuk") {
        return [
          ...this.items,
          { title: "FAQ", icon: "question_answer", to: "/faq" },
        ];
      } else {
        return this.items;
      }
    },
    items() {
      if (this.lang === "pl") {
        return [
          { title: "O nas", icon: "star", to: "/about" },
          { title: "Katalog", icon: "list", to: "/catalogue" },
          { title: "Marki", icon: "build", to: "/brands" },
          { title: "Aktualności", icon: "explore", to: "/news" },
          { title: "Kontakt", icon: "question_answer", to: "/contact" },
        ];
      } else {
        return [
          { title: "About us", icon: "star", to: "/about" },
          { title: "Catalogues", icon: "list", to: "/catalogue" },
          { title: "Brands", icon: "build", to: "/brands" },
          { title: "News", icon: "explore", to: "/news" },
          { title: "Contact us", icon: "question_answer", to: "/contact" },
        ];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.link-active {
  background-color: var(--red);
  color: white;
}

.nav-button {
  word-spacing: 2px;
}

.flex {
  display: flex;
  z-index: 200;
}

.icon {
  z-index: 200;
  display: flex;
  align-items: center;
}

.icon-item {
  width: 30px;
  z-index: 200;

  &.instagram {
    width: 40px;
  }

  &:hover {
    cursor: pointer;
  }
}

.line {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0.3em 0;
  padding: 0;
}

.nav-button-special {
  margin-right: 10px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);

  &.active {
    background-color: var(--red);
    color: white;
  }
}
</style>
