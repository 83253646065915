const LOGIN_ERROR_CODES = ["AUTH001", "AUTH002", "AUTH003", "AUTH004"];

export const sortBy = (items, field) => {
  const copy = [...items];
  return copy.sort((a, b) => a[field].localeCompare(b[field]));
};

export const groupBy = function groupItemBy(array, property) {
  var hash = {},
    props = property.split(".");
  for (var i = 0; i < array.length; i++) {
    var key = props.reduce(function (acc, prop) {
      return acc && acc[prop];
    }, array[i]);
    if (!hash[key]) hash[key] = [];
    hash[key].push(array[i]);
  }
  return hash;
};

export const createParams = (override) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return { ...Object.fromEntries(urlSearchParams.entries()), ...override };
};

export const sortAccordingToList = (desiredOrder) => (a, b) =>
  desiredOrder.indexOf(a) - desiredOrder.indexOf(b);

export const hasLoginError = (code) => LOGIN_ERROR_CODES.includes(code);
