<template>
  <div class="icon">
    <img
      v-if="lang === 'en' && $source === 'ozpartseu'"
      class="icon-item"
      src="./../../../assets/pl512.png"
      alt="Polish"
      width="30px"
      height="30px"
      @click="changeLang"
    />
    <img
      v-if="lang === 'pl'"
      class="icon-item"
      src="./../../../assets/uk512.png"
      alt="English"
      width="30px"
      height="30px"
      @click="changeLang"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { setLanguage } from "@/services/api";

export default {
  name: "LanguageIcon",
  computed: {
    ...mapState(["lang"]),
  },
  methods: {
    changeLang() {
      if (this.lang === "pl") {
        this.$store.commit("setLang", "en");
        setLanguage("en");
      } else {
        this.$store.commit("setLang", "pl");
        setLanguage("pl");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  z-index: 200;
  margin-left: 15px;
}

.icon-item {
  width: 30px;
  z-index: 200;

  &:hover {
    cursor: pointer;
  }
}
</style>
