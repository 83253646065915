<template>
  <div class="upper-part">
    <div class="content">
      <div class="logo">
        <router-link to="/">
          <picture>
            <source
              type="image/webp"
              srcset="./../../../assets/ozparts_logo_standard.webp"
            />
            <img :src="logo" alt="Ozparts" width="200px" height="71px" />
          </picture>
        </router-link>
      </div>
      <mobile-icons />
      <search-bar />
    </div>
  </div>
</template>

<script>
import SearchBar from "../SearchBar.vue";
import MobileIcons from "./MobileIcons.vue";

const logo = require("./../../../assets/ozparts_logo_standard.jpg");

export default {
  name: "NavUpperPart",
  components: { MobileIcons, SearchBar },
  data: () => ({
    logo,
    keyword: "",
  }),
  methods: {
    search() {
      if (this.keyword.length > 1) {
        this.$router.push(`/search/${this.keyword}`).catch((err) => {});
        this.keyword = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  margin: 0 auto;
  width: 200px;
  img {
    width: 100%;
  }
}

.search {
  margin: 20px 0;
}

.buttons {
  .nav-icon {
    margin-right: 0.5em;
    font-size: 1.5em;
  }

  .nav-button-main {
    text-transform: unset;
    letter-spacing: 0.5px;
    font-family: "Nunito Sans", sans-serif;
    margin: 10px 0;

    &:hover {
      text-decoration: none;
    }
  }
}
</style>
